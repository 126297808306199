import styled from "styled-components"
import { media } from "../../styles/theme"

const S = {}

S.ImageGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  padding-bottom: 2rem;

  ${media.laptop`
    grid-row-gap: 2rem;
  `}
`

S.ImageColumns = styled.div`
  display: grid;
  grid-template-columns: 49.5% 49.5%;
  grid-column-gap: 0.5rem;

  ${media.laptop`
    grid-column-gap: 1rem;
  `}
`

S.Description = styled.p`
  margin: 0;
`

S.Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
`

S.PaginationItem = styled.div`
  font-family: ${props => props.theme.fonts.accent};
  font-size: ${props => props.theme.fontSizes.menu};
  width: 50%;
`

S.FeaturesContainer = styled.div`
  display: flex;
  padding-bottom: 2rem;
`

S.Table = styled.table`
  border-spacing: 0;
`

S.TdName = styled.td`
  vertical-align: top;
`

S.TdValue = styled.td`
  padding-left: 3rem;
`

export default S
